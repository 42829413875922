
import Vue from 'vue'
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import SERVICES_CONSTANTS from '@/constants/services'

enum ErrorStatuses {
  tokenIsDead = 404,
  invalidPayloadData = 400,
  wrongService = 418
}

export default Vue.extend({
  name: 'GAResetPage',
  data () {
    return {
      isLoading: false,
      form: {
        fields: {
          smsCode: {
            valid: false,
            codeIsSent: false,
            codeSentBefore: false,
            retryTimeout: 60,
            retries: 3,
            letRetry: false,
            value: '',
            rules: [
              (v: string) => Boolean(v) || this.$t('common.errors.required')
            ],
            errorsBucket: []
          }
        }
      }
    }
  },
  watch: {
    'form.fields.smsCode.retryTimeout' (v) {
      const vm = this as any
      if (v > 0) {
        setTimeout(() => {
          vm.form.fields.smsCode.retryTimeout = v - 1
        }, 1000)
      } else vm.form.fields.smsCode.letRetry = true
    },
    'form.fields.smsCode.value' () {
      const vm = this as any
      vm.form.fields.smsCode.errorsBucket = []
    }
  },
  methods: {
    async sendSMS (): Promise<void> {
      const vm = this as any
      vm.form.fields.smsCode.loading = true
      vm.form.fields.smsCode.codeSentBefore = false
      const response = await vm.$services.authorization.signin.sendSMSToLogin({ sessionId: vm.$route.query.an3K8QkkJb3Mzz })
      if (response.success) {
        vm.form.fields.smsCode.loading = false
        vm.form.fields.smsCode.codeIsSent = true
        vm.form.fields.smsCode.letRetry = false
        vm.form.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
        vm.form.fields.smsCode.retries -= 1
        setTimeout(() => {
          vm.form.fields.smsCode.retryTimeout -= 1
        }, 1000)
      } else {
        vm.form.fields.smsCode.loading = false
        if (response?.data?.work_until) {
          vm.form.fields.smsCode.codeSentBefore = true
          vm.form.fields.smsCode.codeIsSent = true
          vm.form.fields.smsCode.letRetry = false
          vm.form.fields.smsCode.retryTimeout = moment(response.data.work_until).diff(moment(), 'seconds')
          vm.form.fields.smsCode.retries -= 1
          setTimeout(() => {
            vm.form.fields.smsCode.retryTimeout -= 1
          }, 1000)
        } else {
          vm.form.fields.smsCode.codeSendError = true
          vm.form.fields.smsCode.letRetry = true
        }
      }
    },
    handleSuccessLogin (responseData: { [key: string]: string }): void {
      const vm = this as any
      const location = window.location
      const host = location.hostname.split('.').reverse()[1] + '.' + location.hostname.split('.').reverse()[0]
      vm.$cookies.set('jwtr', responseData.refresh_token, {
        expires: moment.unix((jwtDecode(responseData.refresh_token) as any).exp).toDate(),
        domain: '.' + host
      })
      setInterval(() => {
        if (vm.form.successTimer > 0) vm.form.successTimer--
      }, 1000)
      const acceptor = localStorage.getItem('acceptor')
      window.location.href = (acceptor || SERVICES_CONSTANTS.ACCEPTOR_BASE_URL)
    },
    async handleSubmit (): Promise<void> {
      const vm = this as any
      vm.form.isLoading = true
      vm.form.fields.smsCode.errorsBucket = []

      const sessionId = vm.$route.query.an3K8QkkJb3Mzz

      const validateSessionResponse = await vm.$services.authorization.user.validateSession({
        sessionId,
        payload: {
          sms: vm.form.fields.smsCode.value
        }
      })

      if (!validateSessionResponse.success) {
        vm.form.isLoading = false
        vm.form.fields.smsCode.errorsBucket = validateSessionResponse.data.errors
        return
      }

      const response = await vm.$services.authorization.signin.doSignIn({
        session_id: sessionId
      })

      if (response.success) {
        vm.$store.commit('setAppIsRedirecting', true)
        vm.handleSuccessLogin(response.data)
      } else {
        if (response.status === ErrorStatuses.tokenIsDead) {
          vm.$router.replace({ name: 'SignIn' })
        } else if (response.status === ErrorStatuses.invalidPayloadData) {
          vm.smsForm.fields.smsCode.errorsBucket = response.data.errors
        } else if (response.status === ErrorStatuses.wrongService) {
          vm.$router.replace({ name: 'SignIn', query: { errors: 'wrongService' } })
        }
      }

      vm.form.isLoading = false
    }
  }
})
